import React, { useState } from 'react';
import './Header.css';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header>
      <div className="logo">
        Label by SUNCHET
      </div>
      <div className={`nav-links ${menuOpen ? 'open' : ''}`}>
        <a href="#home">Home</a>
        <a href="#about-us">About Us</a>
        <a href="#contact-section">Contact Us</a>
        <a href="#book-appointment">Book Appointment</a>
      </div>
      <div className="hamburger" onClick={toggleMenu}>
        <i className={`fas ${menuOpen ? 'fa-times' : 'fa-bars'}`}></i>
      </div>
    </header>
  );
};

export default Header;
