import React, { useEffect } from 'react';
import './Calendly.css';

const Calendly = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
    <section id="book-appointment" className="calendly-section">
      <div className="calendly-description">
        <h2>Book Your Appointment with Us</h2>
        <p>Thank you for your interest in our boutique. While booking your
          appointment through Calendly, please provide us with your preferred
          date, time, location, and any specific services you are interested in. You will
          receive a confirmation and further details through WhatsApp!</p>
      </div>
      <div className="calendly-container">
        <div
          className="calendly-inline-widget"
          data-url="https://calendly.com/info-sunchet/stitching-appointment"
          style={{ minWidth: '320px', height: '630px' }}
        ></div>
      </div>
    </section>
  );
};

export default Calendly;
