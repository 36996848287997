import React from 'react';
import './AboutUs.css';

const AboutUs = () => {
  return (
    <section id="about-us" className="about-us">
      <h2>About Us</h2>
      <p>Welcome to Label by SUNCHET, where your style meets our passion for fashion. Here’s what makes us your ultimate boutique destination:</p>
      <ul className="custom-bullet-list">
      <li><strong>At-Home Luxury:</strong> Enjoy the sophistication of our boutique brought directly to your door with our exclusive at-home service.</li>
        <li><strong>Curated Exclusivity:</strong> Discover handpicked, exclusive collections that set you apart with unique and trendy designs.</li>
        <li><strong>Uncompromising Quality:</strong> Indulge in the finest fabrics and meticulous craftsmanship for a look that’s as luxurious as it feels.</li>
        <li><strong>Versatile Elegance:</strong> From laid-back chic to glamorous evening wear, we have the perfect ensemble for every occasion and mood.</li>
        <li><strong>Personalized Service:</strong> Experience a new level of customer care with our dedicated team ready to assist and style you.</li>
        <li><strong>Confidence in Style:</strong> Our mission is to elevate your wardrobe with pieces that make you feel confident, stylish, and uniquely you.</li>
      </ul>
      <p>Your perfect outfit is just a visit away!</p>
    </section>
  );
};

export default AboutUs;