import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer>
      <div className="footer-content">
        <h2>Connect with Us</h2>
        <div className="social-links">
          <a href="https://www.instagram.com/yourboutique" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-instagram"></i> Instagram
          </a>
          <a href="https://wa.me/+918595950845" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-whatsapp"></i> WhatsApp
          </a>
          <a href="mailto:Info.sunchet@gmail.com">
            <i className="fas fa-envelope"></i> Email
          </a>
        </div>
        <p>&copy; 2024 Your Boutique. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
