import React from 'react';
import './Contact.css';

const Contact = () => {
  return (
    <section id="contact-section" className="contact-section">
      <div className="contact-container">
        <div className="contact-details">
          <h2>Contact Us</h2>
          <p>Address: Spectrum Mall, Noida</p>
          <p>Phone: (+91) 8595950845</p>
          <p>Email: contact.sunchet@gmail.com</p>
        </div>
        <div className="contact-form">
          <h2>Get in Touch</h2>
          <form action="https://formspree.io/f/your-form-id" method="POST">
            <label htmlFor="name">Name:</label>
            <input type="text" id="name" name="name" required />

            <label htmlFor="email">Email:</label>
            <input type="email" id="email" name="email" required />

            <label htmlFor="subject">Subject:</label>
            <input type="text" id="subject" name="subject" required />

            <label htmlFor="message">Message:</label>
            <textarea id="message" name="message" rows="4" required></textarea>

            <button type="submit">Send</button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Contact;
